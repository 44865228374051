<script setup>
import { defineProps } from "vue";
// const url_path = ref("https://localhost:8000/storage/assets/upload_images/");
// const url_path = ref("https://terrenos.co.mz/");
const url_path = process.env.VUE_APP_URL + "assets/upload_images/";

defineProps({
  product: {
    type: Object,
    required: true,
    default: () => ({
      category: "",
      available: "",
      title: "",
      back_imag: null,
      price: 0,
      last_price: null,
      province_name: "",
      district_name: "",
      agent: { profilePic: null },
      wc: "Não",
      qtybadRoom: 0,
      time_expired: null,
    }),
  },
});

function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}
</script>

<template>
  <div class="w-full rounded-xl">
    <div href="" class="block p-2 bg-white rounded-2xl">
      <!-- Imagem com Ribbon -->
      <router-link
        :to="`/property/${product.pid}`"
        class="relative flex gap-2 overflow-hidden rounded-xl bg-zinc-100"
      >
        <div class="absolute flex flex-wrap gap-1 top-2 left-2">
          <div
            v-if="product.category === 'Aluguer' && product.available === 'Nao'"
            class="px-3 py-1 text-sm font-semibold text-white bg-red-500 rounded-3xl"
          >
            Arrendado
          </div>
          <div
            v-if="product.category === 'Vender' && product.available === 'Nao'"
            class="px-3 py-1 text-sm font-semibold text-white bg-red-500 rounded-3xl"
          >
            Vendido
          </div>
          <div
            v-if="product.category === 'Aluguer'"
            class="px-3 py-1 text-sm font-semibold text-white bg-green-500 rounded-3xl"
          >
            {{ $t("HomePage.Rent") }}
          </div>
          <div
            v-if="product.category === 'Vender'"
            class="px-3 py-1 text-sm font-semibold text-white bg-blue-600 rounded-3xl"
          >
            {{ $t("HomePage.Buy") }}
          </div>
        </div>
        <img
          :src="
            product.back_imag ? url_path + product.back_imag : '/logotipo.png'
          "
          alt="empty"
          class="w-full h-[200px] object-cover rounded-xl"
        />
      </router-link>

      <!-- Título e Avatar do Agente -->
      <div>
        <div class="flex justify-between gap-2 my-2">
          <h4 class="text-base font-semibold">
            <router-link :to="`/property/${product.pid}`">{{
              product.title
            }}</router-link>
          </h4>
          <div class="text-right">
            <img
              :src="
                product.agent?.profilePic
                  ? product.agent.profilePic
                  : '/logotipo.png'
              "
              alt="profile"
              class="object-cover w-10 h-10 rounded-md"
            />
          </div>
        </div>

        <!-- Preço e Último Preço -->
        <div class="flex items-center justify-start pb-4">
          <h6 v-if="product.time_expired" class="line-through text-zinc-500">
            {{ moneyFormat(product.price) }}
          </h6>
          <h6 v-else class="font-bold text-gray-900">
            {{ moneyFormat(product.price) }}
          </h6>
          <h6
            v-if="product.last_price && product.last_price !== product.price"
            class="ml-2 line-through text-zinc-500"
          >
            {{ moneyFormat(product.last_price) }}
          </h6>
        </div>
      </div>

      <!-- Localização -->
      <div class="flex gap-1 text-sm rounded-md lg:col-span-9">
        <span
          class="flex items-center gap-2 px-2 py-1 rounded-full bg-zinc-100"
        >
          <i class="fa fa-map-marker"></i>
        </span>
        <span
          >{{ product.province_name }}, {{ product.district_name }},
          {{ product.childhood }}</span
        >
      </div>

      <hr class="my-4" />

      <!-- Características -->
      <div class="flex items-center justify-between mb-1 rounded-b-md">
        <div class="flex items-center gap-2 px-2 py-1 rounded-md bg-zinc-100">
          <i class="fa fa-tags" aria-hidden="true"></i>
          <span
            :class="{
              'text-red-500': product.time_expired,
              'text-green-500': !product.time_expired,
            }"
          >
            {{ product.time_expired ? "Não Disponível" : "Disponível" }}
          </span>
        </div>
        <div
          v-if="product.wc"
          class="flex items-center gap-2 px-2 py-1 rounded-md bg-zinc-100"
        >
          <i class="fa fa-bath"></i> {{ product.wc }}
        </div>
        <div
          v-if="product.qtybadRoom"
          class="flex items-center gap-2 px-2 py-1 rounded-md bg-zinc-100"
        >
          <i class="fa fa-bed"></i> {{ product.qtybadRoom }}
        </div>
      </div>
    </div>
  </div>
</template>

import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
// import { MainLayout } from "@/layouts"; 
import { GuestRoutes } from "./guest";
import { AdminRoutes } from "./admin";

const routes = [

  GuestRoutes,
  AdminRoutes,
  {
    path: '/auth/google/call-back/:state?',
    component: () => import("@/pages/login/AuthLoading.vue"),
    meta: {
      title: "Hello google"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, top: 0 };
    }
  },
});


router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (![
    "Statistic",
    'Profile',
    'ProfileList',
    'AddProduct',
    'ListMyProducts',
    'UpdateMyProduct',
    'myData',
    'editProfile',
    'adminProfile',
    'updateCredetials',
    'editAdminProfile',
    'addProducts',
    'listProducts',
    "listAvailableProducts",
    'editPorduct',
    'Addagents',
    'listagents',
    'editAgent',
    'ViewAgent',
    "addProporsal",
    "ListProporsal",
    "EditProporsal",
    "ViewProporsal",
    "Showproduct_proporsal",
    "listbeagent",
    "ListCandidate",
    // "Details"
  ]
    .includes(to.name)) {
    import('@/assets/tailwind.css');
    // tailwindLoaded = true;
  } else {
    // tailwindLoaded = false;
    import('@/../public/assets/css/style.css');

  }
  if (to.meta.requiresAuth && !isAuthenticated()) {
    // redirect user to the login page
    next('/login');
  } else {
    //allow user to access user his role
    next();
  }



  // if (to.meta.middleware == "guest") {
  //   if (store.state.auth.authenticated ) {
  //     next("dashboard");
  //   }
  //   next();
  // } else {
  //   if (store.state.auth.authenticated) {
  //     next();
  //   } else {
  //     next({ name: "login" });
  //   }
  // }
});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
    return true;
  } else {
    return false;
  }
  // return true;
}

// f

export default router;

<template>
  <!-- Topbar Start -->

  <!-- <BarMenu :fullName="fullName" :lang="lang" /> -->

  <!-- <div class="demo-register">
    <div class="mt-4 mb-5 text-center">
      <img
        src="https://terrenos.co.mz/TerrenosLogo.png"
        class="img-fluid"
        style="width: 100px; height: 100px"
        alt="logo"
      />
    </div>
    <Login ref="form" @on-submit="handleSubmit">
      <h4 class="mb-4 text-center">{{ $t("FormLogin.AccessAccount") }}</h4>

      <Email name="email" placeholder="Email"  @input="validateEmail" />
      <p v-if="emailError" style="color: red">{{ emailError }}</p>
      <Password
        name="password"
        :rules="passwordRule"
        :placeholder="$t('FormLogin.Password')"

      />
      <p v-if="emailError" style="color: red">{{ emailError }}</p>
      <Poptip trigger="focus" placement="right" width="300">
     
      </Poptip>
      <Submit
        style="
          background-color: #6f42d8;
          color: #fff;
          border: none;
          border-radius: 6px;
          padding: 3px;
        "
      >
        {{
          loading == false ? $t("FormLogin.Access") : $t("FormLogin.Accessing")
        }}</Submit
      >
      <div class="mt-4 mb-2 form-row d-flex justify-content-between col-lg-12">
        <div class="form-group">
          <div class="ml-1 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="basic_checkbox_1 "
            />
            <label class="custom-control-label" for="basic_checkbox_1 ">{{
              $t("FormLogin.RemberMe")
            }}</label>
          </div>
        </div>
        <div class="form-group">
          <router-link to="/forgot-password" class="text-primary"
            >{{ $t("FormLogin.ForgotPassword") }}?
          </router-link>
        </div>
      </div>
    </Login>
    <div class="mt-3 new-account">
      <p>
        {{ $t("FormLogin.SignUp") }}?
        <router-link to="/inscription" class="text-primary">{{
          $t("FormLogin.clickTitle")
        }}</router-link>
      </p>
    </div>
  </div> -->
  <div
    class="flex items-center justify-center min-h-screen bg-gray-100"
    style="
      background: linear-gradient(
        135deg,
        color-mix(in srgb, #0d83fd, transparent 95%) 50%,
        color-mix(in srgb, #0d83fd, transparent 98%) 25%,
        transparent 50%
      );
    "
  >
    <div class="w-full max-w-md p-6 bg-white rounded-2xl">
      <div class="mb-6 text-center">
        <img
          src="/TerrenosLogo.png"
          alt="logo"
          class="object-contain w-24 h-24 mx-auto"
        />
      </div>
      <h4 class="mb-4 text-lg font-semibold text-center">
        {{ $t("FormLogin.AccessAccount") }}
      </h4>

      <!-- Login with google -->
      <div class="mb-4">
        <button
          class="flex items-center w-full gap-4 px-4 py-3 text-sm font-semibold border rounded-md hover:bg-gray-100 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-offset-1"
        >
          <span>
            <svg
              class="h-6"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_13183_10121)">
                <path
                  d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
                  fill="#3F83F8"
                ></path>
                <path
                  d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
                  fill="#34A853"
                ></path>
                <path
                  d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
                  fill="#FBBC04"
                ></path>
                <path
                  d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
                  fill="#EA4335"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_13183_10121">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          <span>
            {{ $t("FormLogin.LoginGoogle") }}
          </span>
        </button>
      </div>

      <div class="flex items-center justify-between gap-4 mb-4">
        <div class="w-1/2 border-b border-gray-300"></div>
        <div class="text-center text-gray-500">
          {{ $t("FormLogin.Or") }}
        </div>
        <div class="w-1/2 border-b border-gray-300"></div>
      </div>
      <!-- Formulário de Login -->
      <form @submit.prevent="handleSubmit">
        <!-- Campo Email -->
        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email
          </label>
          <div class="relative mt-1">
            <input
              type="email"
              id="email"
              class="block w-full h-12 px-4 py-2 text-sm border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 focus:border-blue-500"
              placeholder="Email"
              v-model="data.email"
              @input="validateEmail"
            />
            <div
              class="absolute inset-y-0 flex items-center pointer-events-none right-3"
            >
              <i class="text-gray-400 fa fa-envelope"></i>
            </div>
          </div>
          <p v-if="emailError" class="mt-1 text-xs text-red-500">
            {{ emailError }}
          </p>
        </div>

        <!-- Campo Senha -->
        <div class="mb-4">
          <label for="password" class="block text-sm font-medium text-gray-700">
            {{ $t("FormLogin.Password") }}
          </label>
          <div class="relative mt-1">
            <input
              type="password"
              id="password"
              class="block w-full h-12 px-4 py-2 text-sm border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 focus:border-blue-500"
              :placeholder="$t('FormLogin.Password')"
              v-model="data.password"
            />
            <div
              class="absolute inset-y-0 flex items-center pointer-events-none right-3"
            >
              <i class="text-gray-400 fa fa-key"></i>
            </div>
          </div>
        </div>

        <!-- Checkbox e Link de Recuperação -->
        <div class="flex items-center justify-between mb-4">
          <label class="flex items-center text-sm">
            <input
              type="checkbox"
              class="text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <span class="ml-2">{{ $t("FormLogin.RemberMe") }}</span>
          </label>
          <router-link to="/forgot-password" class="text-sm text-blue-600">
            {{ $t("FormLogin.ForgotPassword") }}?
          </router-link>
        </div>

        <!-- Botão de Acesso -->
        <div class="mb-4">
          <button
            type="submit"
            class="w-full h-12 py-2 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-offset-1"
            :class="{
              'disabled:opacity-80': loading,
            }"
            :disabled="loading"
          >
            {{ loading ? $t("FormLogin.Accessing") : $t("FormLogin.Access") }}
          </button>
        </div>
      </form>

      <!-- Link para Criar Conta -->
      <div class="text-center">
        <p class="text-sm">
          {{ $t("FormLogin.SignUp") }}
          <router-link to="/register" class="text-blue-600">
            {{ $t("FormLogin.clickTitle") }}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
// import Header from "../../components/Header.vue";
// import BarMenu from "@/components/BarMenu.vue";
import store from "@/store";
import { useRoute } from "vue-router";
// import Swal from "sweetalert2";

export default {
  components: {
    // Header,
    // BarMenu,
  },
  data() {
    // const validatePassCheck = (rule, value, callback) => {
    //   if (value !== this.$refs.form.formValidate.password) {
    //     callback(
    //       new Error("A palavra-passe introduzida duas vezes não corresponde!")
    //     );
    //   } else {
    //     callback();
    //   }
    // };
    return {
      data: {
        email: "admin@terrenos.co.mz",
        // email: 'info@administrador.co.mz',
        password: "Nova1234",
        // password: 'uZU65e1O'
      },
      emailError: "",
      loading: false,
      fullName: null,
      route: "",
      path: "",
      lang: store.state.auth.lang,
      showPassword: false,
      URL_API: process.env.VUE_APP_URL_API,
      URL: process.env.VUE_APP_URL,
    };
  },

  computed: {
    validateEmail() {
      // Expressão regular para validar o formato de um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Verifica se o e-mail atende ao formato esperado
      return emailRegex.test(this.data.email)
        ? null
        : "Por favor, insira um endereço de e-mail válido.";
    },
    passwordTip() {
      let strong = "forte";
      // let className = "strong";
      let percent = this.passwordLen > 10 ? 10 : this.passwordLen;
      let color = "#19be6b";

      if (this.passwordLen < 6) {
        strong = "Muito curto";
        // className = "low";
        color = "#ed4014";
      } else if (this.passwordLen < 10) {
        strong = "médio";
        // className = "medium";
        color = "#ff9900";
      }

      return {
        strong,
        class:
          "demo-register-tip-" + this.passwordLen < 6
            ? "low"
            : this.passwordLen < 10
            ? "medium"
            : "strong",
        percent: percent * 10,
        color,
      };
    },
  },

  mounted() {
    this.route = useRoute();
    this.path = this.route.fullPath;
    console.log(store.state.auth.lang);
    if (store.state.auth.authenticated == true) {
      this.fullName = store.state.auth.user.fullName;
    }
    this.lang = store.state.auth.lang;
  },

  methods: {
    showPasswordClick() {
      this.showPassword = !this.showPassword;
    },
    ...mapActions({
      signIn: "auth/login",
    }),
    handleChangePassword(val) {
      this.passwordLen = val.length;
    },

    async handleSubmit() {
      this.loading = true;

      if (this.data.email == "" || this.data.password == "") {
        this.loading = false;
        return;
      }

      await axios
        // .get("http://127.0.0.1:8000/sanctum/csrf-cookie")
        .get(this.URL + "sanctum/csrf-cookie")
        .then((response) => {
          console.log(response);

          axios
            // .post("/api/login", this.data)
            .post(this.URL_API + "login", this.data)
            .then(({ data }) => {
              if (data.status === false) {
                toastr.error(
                  "Desculpe, parece que houve um erro. O nome de usuário ou senha inseridos estão incorretos. Por favor, verifique suas informações e tente novamente"
                );
                return;
              }

              localStorage.setItem("user", JSON.stringify(data));
              toastr.success("Bem vindo!! acedeu com sucesso");
              this.signIn(data);
              // window.location.href = "/dashboard";
            })
            .catch((response) => {
              toastr.error(
                "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
                  response.data.message
              );
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((error) => {
          toastr.error(error);
        });
    },

    //   async handleSubmitButton() {
    //     this.loading = true;
    //     const apiUrl = process.env.VUE_APP_API_URL;

    //     axios
    //       .post(`https://anucios.jdesign.co.mz/Api/api/login`, this.data)
    //       .then(({ data }) => {
    //         if (data.login == false) {
    //                     Swal.fire({
    //                   position:'top-end',
    //                   icon:'success',
    //                   title:'Usuário ou senha inválida',
    //                   showConfirmButton:false,
    //                   timer:1500
    //                 })
    //         } else {
    //           localStorage.setItem("user", JSON.stringify(data));
    //                  Swal.fire({
    //                   position:'top-end',
    //                   icon:'success',
    //                   title:'Bem vindo!! acedeu com sucesso',
    //                   showConfirmButton:false,
    //                   timer:1500
    //                 })

    //           this.signIn(data);
    //           // this.loggedYear(this.data.yearLog);
    //         }
    //       })
    //       .catch((response) => {

    //              Swal.fire({
    //                   position:'top-end',
    //                   icon:'error',
    //                   title:'Erro de autenticacao verifique suas credenciais ou contacte o administrador',
    //                   showConfirmButton:false,
    //                   timer:1500
    //                 })

    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    //   },
  },
};
</script>
<style scoped>
.demo-register {
  width: 400px;
  margin: 0 auto !important;
}
.demo-register .ivu-poptip,
.demo-register .ivu-poptip-rel {
  display: block;
}
.demo-register-tip {
  text-align: left;
}
.demo-register-tip-low {
  color: #ed4014;
}
.demo-register-tip-medium {
  color: #ff9900;
}
.demo-register-tip-strong {
  color: #19be6b;
}
.demo-register-tip-title {
  font-size: 14px;
}
.demo-register-tip-desc {
  white-space: initial;
  font-size: 14px;
  margin-top: 6px;
}
</style>

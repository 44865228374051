<template>
  <div class="bg-zinc-50">
    <header class="fixed top-0 left-0 right-0 z-50 w-full lg:px-4">
      <nav
        class="bg-white border-gray-200 py-2.5 mx-auto max-w-screen-xl lg:mt-4 lg:rounded-full px-2 dark:bg-gray-900"
      >
        <div
          class="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto"
        >
          <RouterLink to="/" class="flex items-center">
            <img
              src="/TerrenosLogo.png"
              class="h-12 mr-3 sm:h-16"
              alt="Terrenos Logo"
            />
            <!-- <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Landwind</span> -->
          </RouterLink>
          <div class="flex items-center lg:order-2">
            <div class="hidden mr-4 sm:inline-block">
              <RouterLink
                to="/login"
                class="font-medium github-button"
                data-size="large"
                data-icon="octicon-star"
                data-show-count="true"
                aria-label="Star themesberg/landwind on GitHub"
              >
                {{ $t("BarMenu.Login") }}
              </RouterLink>
            </div>
            <!-- <RouterLink to="#" class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Log in</RouterLink> -->
            <RouterLink
              to="/register"
              class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-semibold rounded-full text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              >{{ $t("BarMenu.BecomeAgent") }}</RouterLink
            >
            <!-- data-collapse-toggle="mobile-menu-2"
            aria-controls="mobile-menu-2"
            aria-expanded="false" -->
            <button
              type="button"
              @click="openMenu"
              class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                class="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            class="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul
              class="flex flex-col mt-4 font-medium lg:items-center lg:flex-row lg:space-x-8 lg:mt-0"
            >
              <li>
                <RouterLink
                  to="/"
                  :active-class="'!text-primary-700'"
                  class="block py-2 pl-3 pr-4 rounded text-zinc-700 lg:p-0 dark:text-white"
                  aria-current="page"
                >
                  {{ $t("BarMenu.Home") }}
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  :active-class="'!text-primary-700'"
                  to="/search"
                  id="dropdownHoverButton"
                  data-dropdown-toggle="dropdownHover"
                  data-dropdown-trigger="hover"
                  class="flex items-center w-full py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  type="button"
                >
                  {{ $t("BarMenu.BuyRentProperty") }}
                  <svg
                    class="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </RouterLink>

                <!-- Dropdown menu -->
                <div
                  id="dropdownHover"
                  class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownHoverButton"
                  >
                    <li>
                      <RouterLink
                        :active-class="'!text-primary-700'"
                        to="/search?t=Vender"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {{ $t("BarMenu.Buy") }}
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        :active-class="'!text-primary-700'"
                        to="/search?t=Aluguer"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {{ $t("BarMenu.Rent") }}
                      </RouterLink>
                    </li>
                  </ul>
                </div>

                <!-- <RouterLink
                to="#"
                class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >Company</RouterLink
              > -->
              </li>
              <li>
                <RouterLink
                  :active-class="'!text-primary-700'"
                  to="/agents"
                  class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  {{ $t("BarMenu.Agents") }}
                </RouterLink>
              </li>

              <li>
                <RouterLink
                  to="#"
                  class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                >
                  {{ $t("BarMenu.Contact") }}
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        :class="{
          'translate-x-0 opacity-100': isMenuOpen,
          '-translate-x-full opacity-0': !isMenuOpen,
        }"
        class="fixed top-0 left-0 z-50 flex flex-col w-4/5 h-full max-w-sm transition duration-300 transform bg-white shadow-xl"
      >
        <!-- Cabeçalho do menu -->
        <div class="flex items-start justify-between p-5">
          <div class="text-2xl font-bold">
            <RouterLink to="/" class="flex items-center">
              <img
                src="/TerrenosLogo.png"
                class="w-40 mr-3"
                alt="Terrenos Logo"
              />
              <!-- <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Landwind</span> -->
            </RouterLink>
          </div>
          <button @click="closeMenu" class="text-3xl">&times;</button>
        </div>

        <!-- Lista de links -->
        <nav class="flex-1 px-5 mt-6 space-y-6 overflow-y-auto" >
          <RouterLink
            @click="closeMenu"
            to="/login"
            class="flex items-center text-base font-semibold text-gray-700 transition hover:text-indigo-600"
          >
            <i class="mr-3 fa fa-user"></i>
            {{ $t("BarMenu.Login") }}
          </RouterLink>
          <RouterLink
            @click="closeMenu"
            to="/register"
            class="flex items-center text-base font-semibold text-gray-700 transition hover:text-indigo-600"
          >
            <i class="mr-3 fa fa-key"></i>
            {{ $t("BarMenu.BecomeAgent") }}
          </RouterLink>
          <div>
            <RouterLink
              @click="closeMenu"
              to="/search"
              class="flex items-center text-base font-semibold text-gray-700 transition hover:text-indigo-600"
            >
              <i class="mr-3 fa fa-home"></i>
              {{ $t("BarMenu.BuyRentProperty") }}
            </RouterLink>
            <!-- <div class="pl-8 mt-2 space-y-2">
            <RouterLink
            @click="closeMenu"
              to="#"
              class="block text-gray-600 transition hover:text-indigo-500"
              v-text="$t('BarMenu.Buy')"
            ></RouterLink>
            <RouterLink
            @click="closeMenu"
              to="#"
              class="block text-gray-600 transition hover:text-indigo-500"
              v-text="$t('BarMenu.Rent')"
            ></RouterLink>
          </div> -->
          </div>
          <RouterLink
            @click="closeMenu"
            to="/agents"
            class="flex items-center text-base font-semibold text-gray-700 transition hover:text-indigo-600"
          >
            <i class="mr-3 fa fa-building"></i>
            {{ $t("BarMenu.Agents") }}
          </RouterLink>
          <RouterLink
            @click="closeMenu"
            to="/"
            class="flex items-center text-base font-semibold text-gray-700 transition hover:text-indigo-600"
          >
            <i class="mr-3 fa fa-envelope"></i>
            {{ $t("BarMenu.Contact") }}
          </RouterLink>
        </nav>

        <div class="p-4 m-4 border rounded-2xl">
          <p class="mb-2">
            <i class="mr-3 fa fa-map-marker text-primary"></i>Av. Mohamed Siad
            Barre № 582,R/C Maputo
          </p>
          <p class="mb-2">
            <i class="mr-3 fa fa-envelope text-primary"></i
            >terrenostrespasse@mail.mz
          </p>
          <p class="mb-0">
            <i class="mr-3 fa fa-phone text-primary"></i>+25884000000
          </p>
        </div>
      </div>
    </header>

    <main class="min-h-screen">
      <RouterView></RouterView>
    </main>

    <!-- component -->
    <!-- This is an example component -->
    <div class="container py-8">
      <footer class="p-4 bg-white rounded-3xl sm:p-6 dark:bg-gray-800">
        <div class="gap-8 md:flex md:justify-between">
          <div class="w-full mb-6 max-w-[500px] md:mb-0">
            <RouterLink
              to="/"
              class="flex items-center justify-center md:justify-start"
            >
              <img
                src="/TerrenosLogo.png"
                class="h-40 mr-4"
                alt="FlowBite Logo"
              />
            </RouterLink>

            <p class="my-4">{{ $t("Footer.aboutSite") }}</p>
          </div>
          <div class="grid w-full grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
            <div class="col-span-1">
              <h3
                class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white"
              >
                {{ $t("Footer.Resources") }}
              </h3>
              <ul class="space-y-4">
                <li class="">
                  <RouterLink
                    to="/search?t=Vender"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >{{ $t("HomePage.RealState") }}
                    {{ $t("HomePage.RealEstate") }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/search?t=Aluguer"
                    rel="nofollow"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >{{ $t("HomePage.Land") }}
                    {{ $t("HomePage.RealEstate") }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/agents"
                    rel="nofollow"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >{{ $t("Footer.Links.Agents") }}
                  </RouterLink>
                </li>
              </ul>
            </div>
            <div class="col-span-1">
              <h3
                class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white"
              >
                Links
              </h3>
              <ul class="space-y-4">
                <li>
                  <RouterLink
                    to="/register"
                    rel="nofollow"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >{{ $t("BarMenu.BecomeAgent") }}
                  </RouterLink>
                </li>
                <li class="">
                  <RouterLink
                    to="/login"
                    rel="nofollow"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >{{ $t("BarMenu.Login") }}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/"
                    rel="nofollow"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >{{ $t("Footer.Links.ContactUs") }}
                  </RouterLink>
                </li>
                <li class="">
                  <a
                    href="#"
                    target="_blank"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >Privacy Policy</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    class="text-gray-600 hover:underline dark:text-gray-400"
                    >Terms &amp; Conditions</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-span-2">
              <h3
                class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white"
              >
                {{ $t("BarMenu.Contact") }}
              </h3>

              <div class="">
                <p class="mb-2">
                  <i class="mr-3 fa fa-map-marker text-primary"></i>Av. Mohamed
                  Siad Barre № 582,R/C Maputo
                </p>
                <p class="mb-2">
                  <i class="mr-3 fa fa-envelope text-primary"></i
                  >terrenostrespasse@mail.mz
                </p>
                <p class="mb-0">
                  <i class="mr-3 fa fa-phone text-primary"></i>+25884000000
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"
        />
        <div
          class="flex flex-col-reverse items-center gap-4 md:flex-row sm:justify-between"
        >
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400"
            >© 2024
            <a
              href="https://flowbite.com"
              target="_blank"
              class="hover:underline"
              >Terrenos</a
            >. All Rights Reserved.
          </span>
          <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                ></path>
              </svg>
            </a>
            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script setup>
import { initFlowbite } from "flowbite";
import { onMounted, ref } from "vue";
// import '@/assets/tailwind.css'
const isMenuOpen = ref(false);

const openMenu = () => {
  isMenuOpen.value = true;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
});
</script>


import home_main from "@/components/home_main.vue"
export const AdminRoutes = {
    path: "/dashboard",
    component: home_main,
    meta: {
        middleware: "auth",
        requiresAuth: true,
    },
    children: [
        {
            path: "/dashboard",
            component: () => import("@/components/MyHome.vue"),
            meta: {
                title: "Dashboard"
            },

            children: [
                {
                    path: '/dashboard',
                    component: () => import("@/pages/admin/Statistic/StatisticPage.vue"),
                    name: "Statistic",
                    meta: {
                        title: "Statistic"
                    }
                },

                {
                    path:'/profile',
                    name:'Profile',
                    component:()=>import("@/components/mainProfile.vue"),
                    requiresAuth:true,
                    meta:{
                      title:"Profile"
                    },
                
                    children:[
                      {
                        path:'/profile',
                        name:'ProfileList',
                        component:()=>import("@/pages/admin/profile/ListProducts.vue"),
                        meta:{
                          title:'Products'
                        }
                      },
                      {
                        path:'/add-product',
                        name:'AddProduct',
                        component:()=>import("@/pages/admin/profile/addProducts.vue"),
                        mata:{
                          title:'Products'
                        }
                      },
                      {
                        path:'/listMyproducts',
                        name:'ListMyProducts',
                        component:()=>import("@/pages/admin/profile/ListProducts.vue"),
                        mata:{
                          title:'Products'
                        }
                      },
                      {
                        path:'/update-product/:id',
                        component:()=>import("@/pages/admin/profile/EditProduct.vue"),
                        name:'UpdateMyProduct',
                        meta:{
                          title:'Update Product'
                
                        }
                      },
                      {
                        path:"/myData",
                        name:'myData',
                        component:()=>import("@/pages/admin/profile/MyProfile.vue"),
                        meta:{
                          title:'Profile'
                        }
                      },
                      {
                        path:'/edit-profile',
                        name:'editProfile',
                        component:()=>import("@/pages/admin/profile/EditProfile.vue"),
                        meta:{
                          title:'Profile'
                        }
                      },
                      {
                        path:'/update_password',
                        component:()=>import("@/pages/recover_password/update_password.vue")
                      },
                    ]
                  },
                
                {
                    path: '/adminProfile',
                    name: 'adminProfile',
                    component: () => import("@/pages/admin/profile/MyProfile.vue"),
                    meta: {
                        title: 'Profile'
                    }
                },
                {
                    path: '/update_credetials',
                    name: 'updateCredetials',
                    component: () => import("@/pages/recover_password/update_password.vue")
                },

                {
                    path: '/editAdminProfile',
                    name: 'editAdminProfile',
                    component: () => import("@/pages/admin/profile/EditProfile.vue"),
                    meta: {
                        title: 'Edit Prodile'
                    }
                },
                {
                    path: '/add-products',
                    name: 'addProducts',
                    component: () => import("@/pages/admin/Products/addProducts.vue"),
                    meta: {
                        title: "Products"
                    }
                },
                {
                    path: '/list-products',
                    name: 'listProducts',
                    component: () => import("@/pages/admin/Products/ListProducts.vue"),
                    meta: {
                        title: 'List-products'
                    }
                },
                {
                    path: "/list-available-products",
                    name: "listAvailableProducts",
                    component: () => import("@/pages/admin/Products/ListProductsAvailable.vue"),
                    meta: {
                        title: 'List-products'
                    }
                },
                {
                    path: '/editPorduct/:query',
                    name: 'editPorduct',
                    component: () => import("@/pages/admin/Products/EditProduct.vue"),
                    meta: {
                        title: 'Edit Product'
                    }
                },
                {
                    path: '/add-agents',
                    name: 'Addagents',
                    component: () => import("@/pages/admin/Agents/AgentsForm.vue"),
                    meta: {
                        title: 'Agents'
                    }

                },
                {
                    path: '/list-agents',
                    name: 'listagents',
                    component: () => import("@/pages/admin/Agents/ListAgents.vue"),
                    meta: {
                        title: 'Agents'
                    }
                },
                {
                    path: '/edit-Agent/:id',
                    name: 'editAgent',
                    component: () => import("@/pages/admin/Agents/EditAgent.vue"),
                    meta: {
                        title: "Agent"
                    }
                },

                {
                    path: '/view-Agent/:id',
                    name: 'ViewAgent',
                    component: () => import("@/pages/admin/Agents/ViewAgent.vue"),
                    meta: {
                        title: "Agent"
                    }
                },

                {
                    path: '/add-proporsal',
                    name: "addProporsal",
                    component: () => import("@/pages/admin/Proporsal/ProporsalPage.vue"),
                    meta: {
                        title: "Proporsal"
                    }
                },
                {
                    path: '/list-proporsal',
                    name: "ListProporsal",
                    component: () => import("@/pages/admin/Proporsal/ListProporsal.vue"),
                    meta: {
                        title: "Proporsal"
                    }
                },
                {
                    path: '/update-proporsal/:id',
                    name: "EditProporsal",
                    component: () => import("@/pages/admin/Proporsal/EditProporsal.vue"),
                    meta: {
                        title: "Actualizar Proposta"
                    }

                },
                {
                    path: '/view-proporsal/:id',
                    name: "ViewProporsal",
                    component: () => import("@/pages/admin/Proporsal/ViewProporsal.vue"),
                    meta: {
                        title: "Actualizar Proposta"
                    }

                },
                {
                    path: '/show-product_proporsal/:id',
                    name: "Showproduct_proporsal",
                    component: () => import("@/pages/admin/Proporsal/product_proporsal.vue"),
                    meta: {
                        title: "Propostas do Producto"
                    }

                },

                {
                    path: '/list-beAgent',
                    name: "listbeagent",
                    component: () => import("@/pages/admin/Agents/ListBeAgent.vue"),
                    meta: {
                        title: "Quero ser agente"
                    }

                },

                {
                    path: '/list-candidate',
                    name: "ListCandidate",
                    component: () => import("@/pages/admin/Agents/ListFranchise.vue"),
                    meta: {
                        title: "Listar Franquias"
                    }

                }


            ]

        },

    ]
};
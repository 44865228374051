import { createApp } from 'vue'
// import Home_main from './components/home_main.vue'
import AppMain from './AppMain.vue'
import Router from '@/router'
import store from '@/store'
import toastr from 'toastr'
import axios from 'axios'
import VueAxios from 'vue-axios'




import ViewUIPlus from 'view-ui-plus'

import 'view-ui-plus/dist/styles/viewuiplus.css'
import {createI18n} from 'vue-i18n';
import { pt_pt } from './locales/pt_pt.js'
import { EN } from './locales/EN.js'

// Configure o SDK do Facebook com seu ID de aplicação
import VueSocialSharing from 'vue-social-sharing'

// import './assets/tailwind.css'



axios.defaults.withCredentials = true;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('user')}`

// const app = createApp({});
// // app.component(Home_main)
// app.mount("#app")

const languages = {
  pt_pt,
  EN,
};

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'pt_pt', // Defina o idioma padrão
  fallbackLocale: 'pt_pt',
  messages:languages,
});

const app = createApp(AppMain);
app.use(ViewUIPlus);
app.use(VueAxios, axios)
app.use(Router)
app.use(toastr)
app.use(store)
app.use(i18n)
app.use(VueSocialSharing)

app.mount("#app");


import MyLogin from "@/pages/login/MyLogin.vue";
import Home from "@/pages/Home/HomePage.vue"
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export const GuestRoutes = {
    path: "/",
    name: "Home",
    component: DefaultLayout,
    meta: {
        title: "Home"
    },
    children: [
        {
            path: '/property/:id',
            name: 'Details',
            component: () => import("@/pages/Home/HomeDetails.vue"),
            meta: {
                title: "Property Details"
            }
        },
        {
            path: '/search',
            name: 'SearchProperty',
            component: () => import("@/pages/Home/SearchPage.vue"),
            meta: {
                title: "Search Property"
            }
        },
        {
            path: "/login",
            name: "login",
            component: MyLogin,
            meta: {
                middleware: "guest",
                title: `Login`,
            },
        },

        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/pages/recover_password/Recover_password.vue"),
            meta: {
                middleware: "guest",
            },
        },

        {
            path: '/agente',
            name: "Ser agente",
            component: () => import("@/pages/admin/Agents/beAgent.vue"),
            meta: {
                title: 'Ser um Agente'
            }
        },
        {
            path: '/franquia',
            name: "franquia",
            component: () => import("@/pages/admin/Agents/franchiseShowForm.vue"),
            meta: {
                title: 'Franquia'
            }
        },

        {
            path: "/reset_password/:token",
            name: "recover-password",
            component: () => import("@/pages/inscriptions/NewPassword.vue"),
            meta: {
                middleware: "guest",
            },
        },

        {
            path: "/inscription",
            name: "inscription",
            component: () => import("@/pages/inscriptions/MyInscriptions.vue"),
            meta: {
                middleware: "guest",
                title: `Nova Conta`,
            },
        },

        {
            path: "/register",
            name: "register",
            component: () => import("@/pages/inscriptions/Register.vue"),
            meta: {
                middleware: "guest",
                title: `Nova Conta`,
            },
        },



        {
            path: "/",
            name: "dashboard",
            component: Home
        },
        {
            path: '/findProductsByDistrict/:id/:selected',
            name: 'findByDistrict',
            component: () => import("@/pages/Home/ProductsByDistrict.vue"),
            meta: {
                title: 'Products'
            }
        },
        {
            path: '/agents',
            name: 'agents',
            component: () => import("@/pages/Home/HomeSeller.vue")
        },
        {
            path: '/agentProducts/:id',
            name: 'agentProducts',
            component: () => import("@/pages/Home/AgentProducts.vue"),
            meta: {
                title: 'Agent Products'
            }
        },

        {
            path: '/detalhes/:id',
            name: 'Home_details',
            component: Home,
            meta: {
                title: 'Detalhes Producto'
            }

        },



    ],
};
<template>
  <div>
    <MyNavbar :userLogged="userLogged"/>
    <div class="content-page">
      <div class="content">
        <RouterView></RouterView>
      </div>
    </div>
    <!-- <MyFooter /> -->
  </div>
</template>

<script setup>
// import MyFooter from "@/layouts/MyFooter.vue";
import MyNavbar from "@/layouts/MyNavbar.vue";
// import MySideBar from "@/layouts/MySideBar.vue";
import store from "@/store";
import { ref } from "vue";

const userLogged = ref(store.state.auth.authenticated)
</script>

<style>
/* @import url('../../public/assets/css/style.css'); */
</style>

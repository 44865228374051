
<script setup>
import ClientStoreModule from "@/pages/Home/ClientStoreModule";
import { debounce } from "lodash";
import { ref } from "vue";
import { useRouter } from "vue-router";

const fechingLocations = ref(false);
const locationSelected = ref(false);
const filters = ref({
  type: "",
  location: "",
});

const router = useRouter();

const locations = ref([]);

const applyFilters = async () => {
  try {
    fechingLocations.value = true;
    const resp = await ClientStoreModule.actions.searchLocalesList({
      query: filters.value.location,
    });
    locations.value = resp.data;
    fechingLocations.value = false;
  } catch (error) {
    console.error("Error applying filters:", error);
  }
};

const searchLocations = debounce(() => {
  if (filters.value.location.length < 2) {
    locations.value = [];
    return;
  }

  locationSelected.value = false;
  applyFilters();
}, 600);

const selectLocation = (location) => {
  filters.value.location = location.label;
  locations.value = [];
  locationSelected.value = true;

  // route redirect
  router.push({
    name: "SearchProperty",
    query: { l: location.label, t: filters.value.type },
  });
};
</script>

<template>
  <div class="">
    <div
      class="flex flex-col w-full gap-2 py-4 pl-6 pr-6 text-left bg-white divide-y-2 lg:items-center lg:flex-row rounded-3xl lg:rounded-full lg:pl-8 lg:divide-x-2 lg:divide-y-0"
    >
      <div class="flex flex-col gap-2 pb-2 lg:pb-0">
        <label for="search-type" class="font-semibold">{{
          $t("PageFilter.Proposity")
        }}</label>
        <select
          name=""
          id="search-type"
          v-model="filters.type"
          class="border-none !min-w-[200px] px-0 py-0 outline-0 hover:outline-none hover:border-none ring-0 ring-transparent hover:ring-0 hover:ring-transparent"
        >
          <option value="">
            {{ $t("HomePage.RealState") }} / {{ $t("HomePage.Land") }}
          </option>
          <option value="Vender">{{ $t("HomePage.RealState") }}</option>
          <option value="Aluguer">{{ $t("HomePage.Land") }}</option>
        </select>
      </div>
      <div
        class="flex flex-col w-full gap-8 pt-4 justify-beetwen md:flex-row lg:pt-0"
      >
        <div class="flex flex-col flex-1 w-full gap-2 lg:pl-4">
          <label for="search-input" class="w-full font-semibold">{{
            $t("HomePage.SearchProductLabel")
          }}</label>
          <input
            name=""
            :placeholder="$t('HomePage.SearchProductInput')"
            id="search-input"
            v-model="filters.location"
            @keyup="searchLocations"
            class="w-full border-none outline-0 hover:outline-none hover:border-none ring-0 ring-transparent hover:ring-0 hover:ring-transparent"
          />
        </div>
        <div class="flex gap-2">
          <div class="flex-1 w-full">
            <RouterLink
              class="flex items-center justify-center w-full h-12 text-white rounded-full bg-primary-600 md:w-12"
              :to="`/search?l=${filters.location}&t=${filters.type}`"
            >
              <span class="flex items-center gap-4 text-lg text-white">
                <span class="md:hidden">{{ $t("PageFilter.Search") }}</span>
                <span v-if="fechingLocations" class="flex items-center justify-center">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>

                <i v-else class="fa fa-search"></i>
              </span>
            </RouterLink>
          </div>
          <div class="">
            <RouterLink
              :to="`/search`"
              class="flex items-center justify-center w-12 h-12 border rounded-full border-primary-600 text-primary-600"
            >
              <span class="text-lg">
                <!-- <i class="fa fa-search"></i> -->
                <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>
              </span>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="filters.location.length >= 2 && !locationSelected"
      class="pt-[40px] -mt-[40px] gap-2 bg-white rounded-bl-2xl rounded-br-3xl top-full"
    >
      <hr />
      <div
        class="flex flex-col p-4 pb-6 divide-y max-h-[200px] overflow-y-auto"
      >
        <div v-if="fechingLocations" class="flex items-center justify-center gap-2">
          <i class="fa fa-spinner fa-spin"></i>
          <span>{{ $t("HomePage.Searching") }}...</span>
        </div>
        <template v-else>
          <div
            v-if="locations.length === 0"
            class="flex items-center justify-center gap-2"
          >
            <i class="fa fa-exclamation-triangle"></i>
            <span>{{ $t("HomePage.NoResults") }}</span>
          </div>
        </template>
        <div
          v-for="product in locations"
          :key="product.key"
          class="flex items-center gap-4 cursor-pointer"
          @click="selectLocation(product)"
        >
          <span
            class="flex items-center gap-2 px-2 py-1 my-2 rounded-full bg-zinc-100"
          >
            <i class="fa fa-map-marker"></i>
          </span>
          <span class="text-base"> {{ product.label }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
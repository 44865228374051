<script setup>
</script>
<template>
  <div class="pb-1 w-full rounded-xl animate-pulse">
    <div class="block bg-white mb-4 p-4 rounded-2xl">
      <div class="relative overflow-hidden rounded-t-md">
        <div
          class="absolute top-1 left-1 bg-zinc-200 rounded-lg h-6 w-20"
        ></div>
        <div class="w-full h-[200px] bg-zinc-200 rounded-xl"></div>
      </div>
      <div>
        <div class="flex justify-between my-2 gap-2">
          <div class="h-6 w-24 bg-zinc-200 rounded"></div>
          <div class="h-10 w-10 bg-zinc-200 rounded-md"></div>
        </div>
        <div class="flex items-center pb-4 justify-start">
          <div class="h-4 w-32 bg-zinc-200 rounded"></div>
        </div>
      </div>
      <div class="text-sm lg:col-span-9 rounded-md flex gap-1">
        <div class="h-6 w-6 bg-zinc-200 rounded-full"></div>
        <div class="h-4 w-40 bg-zinc-200 rounded"></div>
      </div>
      <hr class="my-4 bg-zinc-200 h-0.5" />
      <div class="flex items-center justify-between mb-1 rounded-b-md">
        <div class="flex gap-2 items-center">
          <div class="h-6 w-24 bg-zinc-200 rounded"></div>
        </div>
        <div class="flex gap-2 items-center">
          <div class="h-6 w-12 bg-zinc-200 rounded"></div>
        </div>
        <div class="flex gap-2 items-center">
          <div class="h-6 w-12 bg-zinc-200 rounded"></div>
        </div>
      </div>
    </div>
  </div>
</template>
